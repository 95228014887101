




















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const takeExamModule = namespace('takeExam')

@Component
export default class TakeExamResult extends Vue {
  @takeExamModule.Getter getExamScore!: number
  @takeExamModule.Getter getExam!: any
  @takeExamModule.Getter getExamStatus!: () => string
  @takeExamModule.Mutation SET_STATUS!: any

  get examScore () {
    return this.getExamScore
  }

  get examProperties () {
    return this.getExam
  }

  get examStatus () {
    return this.getExamStatus
  }

  resetStatus () {
    this.SET_STATUS('')
  }
}
